import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import { ChevronRight } from 'lucide-react';
import config from '../config';
import Breadcrumbs from '../components/Breadcrumbs';
import ArticleEnhancements from '../components/ArticleEnhancements';
import ArticleStructuredData from '../components/ArticleStructuredData';
import TableOfContents from '../components/TableOfContents';
import parse from 'html-react-parser';

const ArticlePage = () => {
  const { category, articleSlug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchArticle();
  }, [category, articleSlug]);

  const fetchArticle = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${config.apiUrl}/articles/${category}/${articleSlug}/`);
      if (!response.ok) {
        throw new Error('Article not found');
      }
      const data = await response.json();
      setArticle(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching article:', error);
      setError('Failed to load the article. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  if (!article) {
    return <div className="container mx-auto px-4 py-8">Article not found.</div>;
  }

  // Function to split content into sections based on <h2> tags
  const splitContentIntoSections = (htmlContent) => {
    const sanitizedContent = DOMPurify.sanitize(htmlContent);
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedContent, 'text/html');
    const body = doc.body;

    const sections = [];
    let currentSection = { title: null, content: [] };

    Array.from(body.children).forEach((node) => {
      if (node.tagName.toLowerCase() === 'h2') {
        // Push the previous section if it exists
        if (currentSection.title) {
          sections.push({ ...currentSection });
          currentSection = { title: null, content: [] };
        }
        currentSection.title = node.textContent;
      } else {
        if (!currentSection.title) {
          // If there's content before the first <h2>, assign it to a default section
          currentSection.title = 'Introduction';
        }
        currentSection.content.push(node.outerHTML);
      }
    });

    // Push the last section
    if (currentSection.title) {
      sections.push({ ...currentSection });
    }

    return sections;
  };

  const sections = splitContentIntoSections(article.content);

  // Function to render sections with alternating background colors
  const renderSections = () => {
    return sections.map((section, index) => {
      const bgColor = index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900';
      return (
        <section key={index} className={`${bgColor} p-4 rounded-lg mb-8 shadow-lg`}>
          <h2 id={article.table_of_contents[index]?.id || `section-${index}`} className="text-2xl font-bold mb-4 text-blue-300 flex items-center">
            <ChevronRight className="inline-block mr-2" size={20} />
            {section.title}
          </h2>
          <div className="prose prose-invert max-w-none">
            {parse(section.content.join(' '))}
          </div>
        </section>
      );
    });
  };

  return (
    <div className="bg-gray-900 min-h-screen text-gray-300">
      <Helmet>
        <title>{`${article.title} - Sortio`}</title>
        <meta name="description" content={article.excerpt} />
        <link rel="canonical" href={`https://www.getsortio.com/articles/${category}/${articleSlug}`} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.excerpt} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.getsortio.com/articles/${category}/${articleSlug}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article.excerpt} />
      </Helmet>

      <div className="container mx-auto px-4 py-8 max-w-5xl">
        <Breadcrumbs category={category} articleTitle={article.title} />

        <div className="flex flex-col lg:flex-row lg:space-x-12">
          <aside className="lg:w-1/4 hidden lg:block sticky top-8 self-start">
            <TableOfContents tableOfContents={article.table_of_contents} />
          </aside>
          <article className="lg:w-3/4">
            <div className="bg-gray-800 p-4 rounded-lg mb-6 shadow-lg">
              <h1 className="text-4xl font-bold mb-2 text-white">{article.title}</h1>
              <p className="text-gray-400">Published on {formatDate(article.created_at)}</p>
            </div>
            
            {article.youtube_video_url && (
              <div className="mb-6">
                <iframe
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${getYouTubeVideoId(article.youtube_video_url)}?rel=0`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg shadow-lg"
                ></iframe>
              </div>
            )}

            <ArticleEnhancements article={article} content={article.content} />
            <div className="space-y-6">
              {renderSections()}
            </div>
          </article>
        </div>
      </div>

      <ArticleStructuredData article={article} category={category} />
    </div>
  );
};

// Helper function to extract YouTube video ID from URL
const getYouTubeVideoId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

export default ArticlePage;