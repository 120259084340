import React, { useState, useEffect, useRef } from 'react';
import { ArrowUp, Facebook, Twitter, Linkedin, Clock, Hash } from 'lucide-react';

const ArticleEnhancements = ({ article, content }) => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [tableOfContents, setTableOfContents] = useState([]);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Generate table of contents
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const headings = tempDiv.querySelectorAll('h2');
    
    const toc = Array.from(headings).map((heading, index) => {
      const id = `section-${index}`;
      heading.id = id;
      return {
        id,
        title: heading.textContent,
      };
    });

    setTableOfContents(toc);

    // Update the content with new IDs
    if (contentRef.current) {
      contentRef.current.innerHTML = tempDiv.innerHTML;
    }
  }, [content]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const estimateReadingTime = () => {
    const wordsPerMinute = 200;
    const wordCount = content.replace(/<[^>]+>/g, '').split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / wordsPerMinute);
    return readingTime;
  };

  const shareUrl = encodeURIComponent(window.location.href);
  const shareTitle = encodeURIComponent(article.title);

  return (
    <>
      <div className="mb-8">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center text-sm text-gray-500">
            <Clock size={16} className="mr-2" />
            {estimateReadingTime()} min read
          </div>
          <div className="flex space-x-4">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-blue-600 hover:bg-blue-700 transition-colors"
            >
              <Facebook size={20} />
            </a>
            <a
              href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-blue-400 hover:bg-blue-500 transition-colors"
            >
              <Twitter size={20} />
            </a>
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareTitle}`}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-blue-700 hover:bg-blue-800 transition-colors"
            >
              <Linkedin size={20} />
            </a>
          </div>
        </div>

        {article.table_of_contents.length > 0 && (
            <div className="mb-6 p-4 bg-gray-800 rounded-lg lg:hidden">
            <h3 className="text-lg font-semibold mb-2">Table of Contents</h3>
            <ul className="space-y-2">
                {article.table_of_contents.map((item) => (
                <li key={item.id}>
                    <a
                    href={`#${item.id}`}
                    className="flex items-center text-blue-400 hover:underline"
                    >
                    <Hash size={16} className="mr-2" />
                    {item.title}
                    </a>
                </li>
                ))}
            </ul>
            </div>
        )}
      </div>

      {showBackToTop && (
        <button
          className="fixed bottom-4 right-4 p-2 bg-blue-600 rounded-full shadow-lg hover:bg-blue-700 transition-colors"
          onClick={scrollToTop}
          aria-label="Back to top"
        >
          <ArrowUp size={24} />
        </button>
      )}
    </>
  );
};

export default ArticleEnhancements;