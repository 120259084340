import React from 'react';
import { FolderSearch, Zap, Wand2, Fingerprint, Sparkles, Edit3 } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description, comingSoon = false }) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:bg-gray-700 relative overflow-hidden">
    {comingSoon && (
      <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs font-bold py-1 px-2 rounded-full">
        Coming Soon
      </div>
    )}
    <Icon className="w-12 h-12 text-blue-500 mb-4" />
    <h3 className="text-xl font-semibold mb-4">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

const FeaturesSection = () => (
  <section id="features" className="py-20 bg-gray-900 text-white">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl font-bold mb-12 text-center">Powerful Features</h2>
      <div className="grid md:grid-cols-3 gap-8">
        <FeatureCard
          icon={FolderSearch}
          title="Intelligent Sorting"
          description="Our AI-powered system organizes your files any way you want. Simply describe your desired organization, and watch the magic happen."
        />
        <FeatureCard
          icon={Zap}
          title="Lightning-Fast Processing"
          description="Experience rapid file organization with our optimized backend, handling large directories with ease."
        />
        <FeatureCard
          icon={Wand2}
          title="Smart Suggestions"
          description="Receive intelligent recommendations for file organization based on your past sorting patterns and preferences."
        />
        <FeatureCard
          icon={Fingerprint}
          title="File DNA"
          description="Unique 'DNA profiles' for your files, allowing for smarter organization and instant identification of duplicates or similar files."
        />
        <FeatureCard
          icon={Sparkles}
          title="Dynamic Folders"
          description="Create 'living' folders that automatically update their contents based on your custom rules and file attributes."
          comingSoon={true}
        />
        <FeatureCard
          icon={Edit3}
          title="Smart Renaming"
          description="Automatically analyze file contents and suggest more appropriate names, making your files easier to find and understand."
          comingSoon={true}
        />
      </div>
    </div>
  </section>
);

export default FeaturesSection;