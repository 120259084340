import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import config from '../config';
import Breadcrumbs from '../components/Breadcrumbs';  // Import Breadcrumbs component

const CategoryPage = () => {
  const { categorySlug } = useParams();
  const [articles, setArticles] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCategoryArticles();
  }, [categorySlug]);

  const fetchCategoryArticles = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${config.apiUrl}/articles/${categorySlug}/`);
      if (!response.ok) {
        throw new Error('Failed to fetch category articles');
      }
      const data = await response.json();
      console.log('Fetched category data:', data);  // Log to check if category has the right structure
      setArticles(data.articles || []);
      setCategory(data.category);  // Set the full category object, including the name
    } catch (err) {
      console.error(err);
      setError('Unable to load articles. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>{`${category?.name || 'Category'} - Sortio Articles`}</title>
        <meta name="description" content={`Articles about ${category?.name || 'this topic'} in the Sortio Blog.`} />
        <link rel="canonical" href={`https://www.getsortio.com/articles/${categorySlug}`} />
      </Helmet>

      {/* Use the category's name for the breadcrumb */}
      <Breadcrumbs category={category?.name || 'Articles'} articleTitle={null} />

      <h1 className="text-3xl font-bold mb-8">{category?.name || 'Category'} Articles</h1>

      <div className="grid md:grid-cols-2 gap-8">
        {articles.map((article) => (
          <article key={article.id} className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-2">
              <Link to={`/articles/${categorySlug}/${article.slug}`} className="text-blue-400 hover:underline">
                {article.title}
              </Link>
            </h2>
            <p className="text-gray-400 mb-2">{formatDate(article.created_at)}</p>
            <p className="mb-4">{stripHtml(article.excerpt)}</p>
            <Link to={`/articles/${categorySlug}/${article.slug}`} className="text-blue-400 hover:underline">
              Read more
            </Link>
          </article>
        ))}
      </div>

      {articles.length === 0 && (
        <p className="text-center text-gray-400">No articles found in this category.</p>
      )}
    </div>
  );
};

export default CategoryPage;
