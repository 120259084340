import React from 'react';
import { Laptop, Apple } from 'lucide-react';

const DownloadPage = () => (
  <div className="min-h-screen bg-gray-900 text-white flex flex-col justify-center items-center px-4">
    <h1 className="text-4xl font-bold mb-8 text-center">Download Sortio</h1>
    <p className="mb-8 text-center">Get started by downloading Sortio for your platform.</p>
    <div className="flex flex-col space-y-4 w-full max-w-md">
      <a
        href="https://github.com/canonicalmg/Sortio-Releases/releases/download/Production/sortio_0.1.0_x64-setup.exe"
        className="bg-blue-600 py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors text-center flex items-center justify-center space-x-2"
      >
        <Laptop size={24} />
        <span>Download for Windows (64-bit)</span>
      </a>
      <a
        href="https://github.com/canonicalmg/Sortio-Releases/releases/download/Production/sortio_0.1.0_x86-setup.exe"
        className="bg-blue-600 py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors text-center flex items-center justify-center space-x-2"
      >
        <Laptop size={24} />
        <span>Download for Windows (32-bit)</span>
      </a>
      <a
        href="https://github.com/canonicalmg/Sortio-Releases/releases/download/Production/sortio_0.1.0_universal.dmg"
        className="bg-blue-600 py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors text-center flex items-center justify-center space-x-2"
      >
        <Apple size={24} />
        <span>Download for macOS</span>
      </a>
    </div>
    <p className="mt-8 text-sm text-center text-gray-400">
      While we wait for our certificates to be approved, you may need to right-click and explicitly select "Open" to run the application.
    </p>
  </div>
);

export default DownloadPage;