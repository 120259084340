import React, { useState } from 'react';
import { Folder, Eye, X, Loader, RotateCcw } from 'lucide-react';
import axios from 'axios';

// FileNode Component: Represents individual files and folders
const FileNode = ({ node, level, onSelect }) => {
  return (
    <div className="file-node">
      <div
        className="flex items-center cursor-pointer text-white hover:bg-gray-700 p-1 rounded"
        style={{ paddingLeft: `${level * 20}px` }}
        onClick={() => onSelect(node)}
      >
        {node.type === 'folder' ? (
          <span className="mr-2">📂</span>
        ) : (
          <span className="mr-6">📄</span>
        )}
        <span>{node.name}</span>
      </div>
      {node.type === 'folder' && node.children && (
        <div>
          {node.children.map((child) => (
            <FileNode key={child.id} node={child} level={level + 1} onSelect={onSelect} />
          ))}
        </div>
      )}
    </div>
  );
};

// FileExplorer Component: Displays the entire filesystem
const FileExplorer = ({ fileSystem, onSelect }) => {
  return (
    <div className="file-explorer overflow-auto h-96">
      {fileSystem.map((node) => (
        <FileNode key={node.id} node={node} level={0} onSelect={onSelect} />
      ))}
    </div>
  );
};

// FolderSelector Component: Modal for selecting a folder
const FolderSelector = ({ isOpen, onClose, fileSystem, onSelect }) => {
  if (!isOpen) return null;

  const renderFolders = (folders) => {
    return folders.map((node) => (
      <div key={node.id}>
        {node.type === 'folder' && (
          <button
            className="w-full text-left mb-2 p-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors"
            onClick={() => {
              onSelect(node);
              onClose();
            }}
          >
            <Folder className="inline-block mr-2" size={16} />
            {node.name}
          </button>
        )}
        {node.children && node.children.length > 0 && (
          <div className="ml-4">
            {renderFolders(node.children)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-white">Select a Folder</h2>
        <p className="text-gray-300 mb-4">Choose a folder from the file system to sort.</p>
        <div className="mt-4 max-h-96 overflow-auto">
          {renderFolders(fileSystem)}
        </div>
        <button
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Main DemoSection Component
const DemoSection = () => {
  const initialFileSystem = [
    {
      id: 1,
      name: 'My Documents',
      type: 'folder',
      path: 'My Documents',
      children: [
        { id: 2, name: 'tax_return_2022.pdf', type: 'file', path: 'My Documents/tax_return_2022.pdf' },
        { id: 3, name: 'w2_2022.pdf', type: 'file', path: 'My Documents/w2_2022.pdf' },
        { id: 4, name: 'family_photo_2023.jpg', type: 'file', path: 'My Documents/family_photo_2023.jpg' },
        { id: 5, name: 'resume_2023.docx', type: 'file', path: 'My Documents/resume_2023.docx' },
        { id: 6, name: 'project_proposal.pptx', type: 'file', path: 'My Documents/project_proposal.pptx' },
        { id: 7, name: 'budget_2023.xlsx', type: 'file', path: 'My Documents/budget_2023.xlsx' },
        { id: 8, name: 'vacation_itinerary.pdf', type: 'file', path: 'My Documents/vacation_itinerary.pdf' },
        { id: 9, name: 'home_inventory.csv', type: 'file', path: 'My Documents/home_inventory.csv' },
        { id: 10, name: 'birthday_party.mov', type: 'file', path: 'My Documents/birthday_party.mov' },
        { id: 11, name: 'work_contract.pdf', type: 'file', path: 'My Documents/work_contract.pdf' }
      ]
    }
  ];

  const [fileSystem, setFileSystem] = useState(initialFileSystem);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [sortingPrompt, setSortingPrompt] = useState('');
  const [isSorting, setIsSorting] = useState(false);
  const [sorted, setSorted] = useState(false);
  const [isFolderSelectorOpen, setIsFolderSelectorOpen] = useState(false);
  const [sortingPlan, setSortingPlan] = useState(null);
  const [originalFileSystem, setOriginalFileSystem] = useState(null);

  const promptExamples = [
    'Sort by file type and project',
    'Organize by date and importance',
    'Categorize work, personal, and media files',
  ];

  const handleSelectFolder = () => {
    setIsFolderSelectorOpen(true);
  };

  const handleFolderSelection = (folder) => {
    setSelectedFolder(folder);
  };

  const handlePromptChange = (e) => {
    setSortingPrompt(e.target.value);
  };

  const handleSortClick = async () => {
    if (selectedFolder && sortingPrompt.trim() !== '') {
      setIsSorting(true);

      const filenames = getFilenamesInFolder(selectedFolder);
      const files = filenames.map(file => ({
        name: file,
        creationDate: new Date().toISOString() // Using current date for demo purposes
      }));

      try {
        const response = await axios.post('https://app.getsortio.com/api/sorting-plan/', {
          files: files,
          sorting_prompt: sortingPrompt
        }, {
          headers: {
            'Authorization': `Bearer DEMO`,
            'Content-Type': 'application/json'
          }
        });

        const result = response.data;

        if (!result.newFolders || !Array.isArray(result.newFolders) || 
            !result.sortingPlan || !Array.isArray(result.sortingPlan)) {
          throw new Error('Invalid response structure from API');
        }

        setSortingPlan(result);
        setIsSorting(false);
        setSorted(true);

        // Store the original file system before applying changes
        setOriginalFileSystem(JSON.parse(JSON.stringify(fileSystem)));

        // Update the file system based on the sorting plan
        const updatedFileSystem = applySortingPlan(fileSystem, selectedFolder.path, result);
        setFileSystem(updatedFileSystem);

        // Clear selections after sorting
        setSelectedFolder(null);
        setSortingPrompt('');
      } catch (error) {
        console.error('Error generating sorting plan:', error);
        setIsSorting(false);
      }
    }
  };

  const applySortingPlan = (currentFileSystem, basePath, plan) => {
    const updatedFileSystem = JSON.parse(JSON.stringify(currentFileSystem)); // Deep copy

    const findFolder = (path) => {
      const parts = path.split('/');
      let current = updatedFileSystem[0]; // Start from the root

      for (let i = 1; i < parts.length; i++) {
        const part = parts[i];
        const found = current.children.find(child => child.name === part && child.type === 'folder');
        if (!found) {
          const newFolder = {
            id: Date.now() + Math.random(),
            name: part,
            type: 'folder',
            path: parts.slice(0, i + 1).join('/'),
            children: []
          };
          current.children.push(newFolder);
          current = newFolder;
        } else {
          current = found;
        }
      }
      return current;
    };

    // Create new folders
    plan.newFolders.forEach(folderName => {
      findFolder(`${basePath}/${folderName}`);
    });

    // Move files according to the sorting plan
    plan.sortingPlan.forEach(item => {
      const sourceFolder = findFolder(basePath);
      const targetFolder = findFolder(`${basePath}/${item.targetFolder}`);
      
      const fileIndex = sourceFolder.children.findIndex(child => child.name === item.file && child.type === 'file');
      if (fileIndex !== -1) {
        const [file] = sourceFolder.children.splice(fileIndex, 1);
        file.path = `${targetFolder.path}/${file.name}`;
        targetFolder.children.push(file);
      }
    });

    return updatedFileSystem;
  };

  const handleRevert = () => {
    if (originalFileSystem) {
      setFileSystem(originalFileSystem);
      setOriginalFileSystem(null);
      setSorted(false);
      setSortingPlan(null);
    }
  };

  const handleCancel = () => {
    setSelectedFolder(null);
    setSortingPrompt('');
    setSorted(false);
    setSortingPlan(null);
  };

  const getFilenamesInFolder = (folder) => {
    const filenames = [];
    const traverseFolder = (node) => {
      if (node.type === 'file') {
        filenames.push(node.name);
      } else if (node.type === 'folder' && node.children) {
        node.children.forEach(traverseFolder);
      }
    };
    traverseFolder(folder);
    return filenames;
  };

  return (
    <section id="demo" className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-8 text-center text-white transition duration-300 ease-in-out hover:text-shadow-lg" style={{ textShadow: "0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.3)", '--tw-text-shadow-lg': '0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6)' }}>
  Experience Sortio in Action
</h2>
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Side: File Explorer */}
          <div className="w-full md:w-1/2 bg-gray-800 p-4 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-4 text-white text-center">Demo Filesystem</h3>
            <FileExplorer fileSystem={fileSystem} onSelect={() => {}} />
          </div>

          {/* Right Side: Sortio App UI */}
          <div className="w-full md:w-1/2">
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <button
                onClick={handleSelectFolder}
                className={`flex items-center justify-center py-2 px-4 rounded-lg transition-colors w-full ${
                  selectedFolder
                    ? 'bg-blue-500 text-white hover:bg-blue-600'
                    : 'bg-blue-500 text-white hover:bg-blue-600 animate-pulse'
                }`}
                style={{
                  boxShadow: selectedFolder ? 'none' : '0 0 10px 2px rgba(59, 130, 246, 0.8)' // Apply glow effect until folder is selected
                }}
              >
                <Folder className="mr-2" />
                Select Folder
              </button>

              {selectedFolder && (
                <div className="mt-4 p-3 bg-gray-900 rounded-lg">
                  <p className="text-white text-sm">Selected: {selectedFolder.path}</p>
                </div>
              )}

            <div className="mt-4">
                <input
                  type="text"
                  value={sortingPrompt}
                  onChange={handlePromptChange}
                  placeholder="Describe how you want to sort your files..."
                  className={`w-full p-3 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ${
                    sortingPrompt.trim() === '' && selectedFolder
                      ? 'animate-pulse' // Pulse effect until there's text
                      : ''
                  }`}
                  style={{
                    boxShadow: sortingPrompt.trim() === '' && selectedFolder ? '0 0 10px 2px rgba(59, 130, 246, 0.8)' : 'none' // Glow effect until text is entered
                  }}
                />
              </div>

              <div className="mt-4 flex flex-wrap gap-2">
                {promptExamples.map((prompt, index) => (
                  <button
                    key={index}
                    onClick={() => setSortingPrompt(prompt)}
                    className="bg-gray-900 text-white py-2 px-4 rounded-full text-sm hover:bg-gray-700 transition-colors" 
                  >
                    {prompt}
                  </button>
                ))}
              </div>

              <div className="mt-4 flex justify-between">
              <button
                  onClick={handleSortClick}
                  disabled={!selectedFolder || sortingPrompt.trim() === '' || isSorting}
                  className={`flex items-center justify-center py-2 px-4 rounded-lg transition-colors ${
                    (!selectedFolder || sortingPrompt.trim() === '' || isSorting)
                      ? 'opacity-50 cursor-not-allowed'
                      : 'bg-green-700 text-white hover:bg-green-600'
                  } ${!isSorting && sortingPrompt.trim() != '' && selectedFolder ? 'animate-pulse' : ''}`} // Pulse effect until clicked
                  style={{
                    boxShadow: !isSorting && sortingPrompt.trim() != '' && selectedFolder ? '0 0 10px 2px rgba(72, 187, 120, 0.8)' : 'none' // Glow effect until clicked
                  }}
                >
                  {isSorting ? (
                    <Loader className="animate-spin mr-2" />
                  ) : (
                    <>
                      <Eye className="mr-2" />
                      Sort Folder
                    </>
                  )}
                </button>
                {sorted ? (
                  <button
                    onClick={handleRevert}
                    className="flex items-center justify-center bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600 transition-colors"
                  >
                    <RotateCcw className="mr-2" />
                    Revert
                  </button>
                ) : (null)}
              </div>

              {sorted && (
                <div className="mt-4 text-green-400">
                  <p>Sorting completed! Check the filesystem for changes.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <FolderSelector
        isOpen={isFolderSelectorOpen}
        onClose={() => setIsFolderSelectorOpen(false)}
        fileSystem={fileSystem}
        onSelect={handleFolderSelection}
      />
    </section>
  );
};

export default DemoSection;