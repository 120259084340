// HeroSection.jsx
import React, { useRef, useMemo, Suspense, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { ChevronRight } from 'lucide-react';
import * as THREE from 'three';

// Main HeroSection Component
const HeroSection = () => (
  <section className="relative py-20 text-center text-white overflow-hidden bg-gray-900">
    {/* 3D Canvas Background */}
    <div className="absolute top-0 left-0 w-full h-full">
      <Canvas
        camera={{ position: [0, 0, 10], fov: 50 }}
        style={{ background: '#1a202c' }} // Tailwind's gray-900
      >
        {/* Lighting */}
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} intensity={1} />

        {/* Suspense for lazy loading */}
        <Suspense fallback={null}>
          <AnimatedGrid />
          <SpawningFiles />
        </Suspense>

        {/* Optional: Add stars or other background elements */}
        {/* <Stars /> */}

        {/* Optional: Disable orbit controls for better performance */}
        {/* <OrbitControls enableZoom={false} enablePan={false} /> */}
      </Canvas>
    </div>

    {/* Content Overlay */}
    <div className="relative container mx-auto px-4 z-10">
      <h1 className="text-5xl font-bold mb-6">Welcome to Sortio</h1>
      <p className="text-xl mb-8">AI-Powered File Organization at Your Fingertips</p>
      <div className="flex justify-center space-x-4">
        <button 
          onClick={() => window.location.href = "/download"}
          className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-6 rounded-full transition duration-300 flex items-center"
        >
          Download Now <ChevronRight className="ml-2" size={20} />
        </button>
      </div>

    </div>
  </section>
);

export default HeroSection;

// AnimatedGrid Component
const AnimatedGrid = () => {
  const groupRef = useRef();
  const numLines = 50; // Number of lines in each direction
  const spacing = 0.5; // Spacing between lines
  const speed = 0.01; // Speed of downward movement

  // Generate grid lines
  const lines = useMemo(() => {
    const positions = [];

    // Horizontal lines (parallel to X-axis)
    for (let i = -numLines; i <= numLines; i++) {
      positions.push(-numLines * spacing, i * spacing, 0);
      positions.push(numLines * spacing, i * spacing, 0);
    }

    // Vertical lines (parallel to Z-axis)
    for (let i = -numLines; i <= numLines; i++) {
      positions.push(i * spacing, -numLines * spacing, 0);
      positions.push(i * spacing, numLines * spacing, 0);
    }

    return new Float32Array(positions);
  }, [numLines, spacing]);

  // Create BufferGeometry
  const geometry = useMemo(() => {
    const geom = new THREE.BufferGeometry();
    geom.setAttribute('position', new THREE.BufferAttribute(lines, 3));
    return geom;
  }, [lines]);

  // Animation Loop
  useFrame(() => {
    if (groupRef.current) {
      // Move the group downward
      groupRef.current.position.y -= speed;

      // Reset position when it moves below a certain threshold
      if (groupRef.current.position.y < -spacing) {
        groupRef.current.position.y += spacing;
      }
    }
  });

  return (
    <group ref={groupRef}>
      <lineSegments geometry={geometry}>
        <lineBasicMaterial
          attach="material"
          color="#4A5568" // Subtle gray color
          transparent
          opacity={0.3} // Make it subtle
        />
      </lineSegments>
    </group>
  );
};

// SpawningFiles Component
const SpawningFiles = () => {
  const groupRef = useRef();
  const [files, setFiles] = useState([]);

  const spawnInterval = 2000; // Spawn a file every 2 seconds
  const fileSpeed = 0.02; // Speed at which files move downward
  const fileLifetime = 6000; // Time (ms) before a file is removed after spawning

  // Predefined muted color palette
  const mutedColors = [
    '#4A5568', // Gray
    '#2C7A7B', // Teal
    '#718096', // Blue Gray
    '#A0AEC0', // Light Gray
    '#CBD5E0', // Cool Gray
  ];

  // Function to spawn a new file
  const spawnFile = () => {
    const xRange = 40; // Adjust based on your grid size
    const x = (Math.random() - 0.5) * xRange * 2; // Random x between -xRange and xRange
    const zRange = 2; // Depth range if needed
    const z = (Math.random() - 0.5) * zRange * 2; // Random z between -zRange and zRange

    const newFile = {
      id: THREE.MathUtils.generateUUID(),
      position: new THREE.Vector3(x, 10, z), // Start above the view
      rotation: new THREE.Euler(
        Math.random() * Math.PI,
        Math.random() * Math.PI,
        Math.random() * Math.PI
      ),
      color: mutedColors[Math.floor(Math.random() * mutedColors.length)],
      createdAt: Date.now(),
    };

    setFiles((prevFiles) => [...prevFiles, newFile]);
  };

  // Spawn files at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      spawnFile();
    }, spawnInterval);

    return () => clearInterval(interval);
  }, []);

  // Animation Loop
  useFrame(() => {
    setFiles((prevFiles) =>
      prevFiles
        .map((file) => {
          const newPosition = file.position.clone();
          newPosition.y -= fileSpeed;
          return { ...file, position: newPosition };
        })
        .filter((file) => file.position.y > -10) // Remove files that moved out of view
    );
  });

  return (
    <group ref={groupRef}>
      {files.map((file) => (
        <mesh key={file.id} position={file.position} rotation={file.rotation}>
          <boxGeometry args={[0.3, 0.3, 0.3]} />
          <meshStandardMaterial color={file.color} />
        </mesh>
      ))}
    </group>
  );
};
