import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="py-6 bg-gray-800 text-gray-400">
    <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
      <p>&copy; 2024 Sortio. All rights reserved.</p>
      <nav className="mt-4 md:mt-0">
        <ul className="flex flex-wrap justify-center md:justify-end space-x-4">
          <li><Link to="/about-us" className="hover:text-white transition-colors">About Us</Link></li>
          <li><Link to="/terms-and-conditions" className="hover:text-white transition-colors">Terms & Conditions</Link></li>
          <li><Link to="/privacy-policy" className="hover:text-white transition-colors">Privacy Policy</Link></li>
          <li><a href="/#contact" className="hover:text-white transition-colors">Contact</a></li>
        </ul>
      </nav>
    </div>
  </footer>
);

export default Footer;