import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import DemoSection from './components/DemoSection';
import FeaturesSection from './components/FeaturesSection';
import SecuritySection from './components/SecuritySection';
import PricingSection from './components/PricingSection';
import FAQsSection from './components/FAQsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import DownloadPage from './pages/DownloadPage';
import ArticlesPage from './pages/ArticlesPage';
import ArticlePage from './pages/ArticlePage';
import CategoryPage from './pages/CategoryPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import AboutUsPage from './pages/AboutUsPage';
import { Analytics } from '@vercel/analytics/react';

const App = () => {
  return (
    <Router>
      <div className="bg-gradient-to-b from-gray-900 to-gray-800 min-h-screen text-white">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <DemoSection />
              <SecuritySection />
              <FeaturesSection />
              <PricingSection />
              <FAQsSection />
              <ContactSection />
            </>
          } />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/articles" element={<ArticlesPage />} />
          <Route path="/articles/:category/:articleSlug" element={<ArticlePage />} />
          <Route path="/articles/:categorySlug" element={<CategoryPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
        </Routes>
        <Footer />
        <Analytics />
      </div>
    </Router>
  );
};

export default App;