import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-700">
      <button
        className="flex justify-between items-center w-full py-4 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold">{question}</span>
        {isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
      </button>
      {isOpen && (
        <div className="pb-4">
          <p className="text-gray-300">{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQsSection = () => {
  const faqs = [
    {
      question: "How does the free trial work?",
      answer: "You can try all features of Sortio free for a limited amount of time. No credit card is required to start your trial. Simply download the app and sign up to begin."
    },
    {
      question: "Can I revert changes made by Sortio?",
      answer: "Yes, Sortio backs up your files before making any changes. You can easily revert to the original organization at any time from within the app."
    },
    {
      question: "How long is the trial period?",
      answer: "You can use Sortio for free on up to 100 files before the trial period ends."
    },
    {
      question: "What happens after the free trial ends?",
      answer: "After the trial, you'll be prompted to subscribe to continue using Sortio. If you choose not to subscribe, you'll still have access to your organized files, but you won't be able to perform new sorting operations."
    },
    {
      question: "Can I use Sortio on multiple devices?",
      answer: "Yes, your Sortio subscription allows you to use the app on multiple devices."
    },
    {
      question: "How does the AI-powered sorting work?",
      answer: "Sortio uses advanced machine learning algorithms to understand the context and content of your files. It then organizes them based on your instructions or preferences."
    },
    {
      question: "Is there a limit to how many files I can sort?",
      answer: "There's no limit to the number of files you can sort with Sortio. However, very large directories may take longer to process."
    }
  ];

  return (
    <section id="faqs" className="py-20 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQsSection;