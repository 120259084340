import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const Breadcrumbs = ({ category, articleTitle }) => (
  <nav className="text-sm mb-4">
    <ol className="list-none p-0 inline-flex">
      <li className="flex items-center">
        <Link to="/" className="text-blue-400 hover:underline">Home</Link>
        {category || articleTitle ? <ChevronRight size={16} className="mx-2" /> : null}
      </li>
      <li className="flex items-center">
        <Link to="/articles" className="text-blue-400 hover:underline">Articles</Link>
        {category || articleTitle ? <ChevronRight size={16} className="mx-2" /> : null}
      </li>
      {category && (
        <li className="flex items-center">
          <span className="text-blue-400">{category}</span>
        </li>
      )}
      {articleTitle && (
        <>
          <ChevronRight size={16} className="mx-2" />
          <li className="text-gray-400">{articleTitle}</li>
        </>
      )}
    </ol>
  </nav>
);

export default Breadcrumbs;
