import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="sticky top-0 bg-gray-900 z-50">
      <div className="container mx-auto px-4 py-2">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src="/logo.png" alt="Sortio Logo" className="h-12 w-auto" />
            <div className="text-xl font-bold ml-2 text-white">Sortio</div>
          </Link>
          <button className="md:hidden text-white" onClick={toggleMenu}>
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          <nav className="hidden md:block">
            <ul className="flex space-x-4">
              <li><a href="/#demo" className="text-white hover:text-blue-400 transition">Demo</a></li>
              <li><a href="/#security" className="text-white hover:text-blue-400 transition">Security</a></li>
              <li><a href="/#features" className="text-white hover:text-blue-400 transition">Features</a></li>
              <li><a href="/#pricing" className="text-white hover:text-blue-400 transition">Pricing</a></li>
              <li><a href="/#faqs" className="text-white hover:text-blue-400 transition">FAQs</a></li>
              <li><Link to="/articles" className="text-white hover:text-blue-400 transition">Articles</Link></li>
              <li><Link to="/download" className="text-white hover:text-blue-400 transition">Download</Link></li>
              <li><a href="/#contact" className="text-white hover:text-blue-400 transition">Contact</a></li>
            </ul>
          </nav>
        </div>
        {isMenuOpen && (
          <nav className="md:hidden mt-4">
            <ul className="space-y-2">
              <li><a href="/#demo" className="block text-white hover:text-blue-400 transition" onClick={toggleMenu}>Demo</a></li>
              <li><a href="/#security" className="block text-white hover:text-blue-400 transition" onClick={toggleMenu}>Security</a></li>
              <li><a href="/#features" className="block text-white hover:text-blue-400 transition" onClick={toggleMenu}>Features</a></li>
              <li><a href="/#pricing" className="block text-white hover:text-blue-400 transition" onClick={toggleMenu}>Pricing</a></li>
              <li><a href="/#faqs" className="block text-white hover:text-blue-400 transition" onClick={toggleMenu}>FAQs</a></li>
              <li><Link to="/articles" className="block text-white hover:text-blue-400 transition" onClick={toggleMenu}>Articles</Link></li>
              <li><Link to="/download" className="block text-white hover:text-blue-400 transition" onClick={toggleMenu}>Download</Link></li>
              <li><a href="/#contact" className="block text-white hover:text-blue-400 transition" onClick={toggleMenu}>Contact</a></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;