import React from 'react';
import { Shield, Lock, Brain } from 'lucide-react';

const SecurityFeature = ({ icon: Icon, title, description }) => (
  <div className="flex items-start mb-6">
    <div className="mr-4">
      <Icon className="text-blue-500" size={24} />
    </div>
    <div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  </div>
);

const SecuritySection = () => (
  <section id="security" className="py-20 bg-gray-800 text-white">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-12 text-center">Secure by Design</h2>
      <div className="md:flex md:items-center md:justify-center">
        <div className="md:w-3/4">
          <div className="space-y-8">
            <SecurityFeature
              icon={Shield}
              title="End-to-End Encryption"
              description="Your file metadata is encrypted end-to-end, ensuring that only you can access the details of your sorted files."
            />
            <SecurityFeature
              icon={Lock}
              title="Secure Authentication"
              description="We use Auth0, a leader in identity management, to ensure secure login and protect your account."
            />
            <SecurityFeature
              icon={Brain}
              title="Intelligent Privacy-Preserving Processing"
              description="Our advanced AI-powered sorting algorithms work with encrypted file metadata, ensuring your content remains private while delivering smart organization."
            />
          </div>
          <p className="mt-8 text-sm text-center text-gray-400">
            We're committed to maintaining the highest standards of security and privacy. 
            For more details, please check our full security policy.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default SecuritySection;