import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import config from '../config';
import Breadcrumbs from '../components/Breadcrumbs';

const ArticlesPage = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchArticlesAndCategories();
  }, []);

  const fetchArticlesAndCategories = async () => {
    try {
      setLoading(true);
      const [articlesResponse, categoriesResponse] = await Promise.all([
        fetch(`${config.apiUrl}/articles/`),
        fetch(`${config.apiUrl}/categories/`)
      ]);

      if (!articlesResponse.ok || !categoriesResponse.ok) {
        throw new Error('Failed to fetch data');
      }

      const articlesData = await articlesResponse.json();
      const categoriesData = await categoriesResponse.json();

      setArticles(articlesData.articles || []);
      setCategories(categoriesData.categories || []);
      setError(null);
    } catch (err) {
      console.error(err);
      setError('Unable to load articles. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>Articles - Sortio</title>
        <meta name="description" content="Explore insightful articles on AI-powered file organization and more." />
        <link rel="canonical" href="https://www.getsortio.com/articles" />
        <meta property="og:title" content="Articles - Sortio" />
        <meta property="og:description" content="Explore insightful articles on AI-powered file organization and more." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.getsortio.com/articles" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Articles - Sortio" />
        <meta name="twitter:description" content="Explore insightful articles on AI-powered file organization and more." />
      </Helmet>

      <Breadcrumbs category="Articles" articleTitle="" />

      <h1 className="text-4xl font-bold mb-8 text-center text-white">Articles</h1>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-white">Categories</h2>
        <div className="flex flex-wrap gap-4">
          {categories.map((category) => (
            <Link
              key={category.id}
              to={`/articles/${category.slug}`}
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded transition-colors"
            >
              {category.name}
            </Link>
          ))}
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        {articles.map((article) => (
          <article key={article.id} className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-2">
              <Link to={`/articles/${article.category_slug}/${article.slug}`} className="text-blue-400 hover:underline">
                {article.title}
              </Link>
            </h2>
            <p className="text-gray-400 mb-2">{formatDate(article.created_at)}</p>
            <p className="mb-4">{stripHtml(article.excerpt)}</p>
            <Link to={`/articles/${article.category_slug}/${article.slug}`} className="text-blue-400 hover:underline">
              Read more
            </Link>
          </article>
        ))}
      </div>

      {articles.length === 0 && (
        <p className="text-center text-gray-400">No articles found.</p>
      )}
    </div>
  );
};

export default ArticlesPage;
