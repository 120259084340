const dev = {
    apiUrl: 'http://0.0.0.0:8000/api'
  };
  
  const prod = {
    apiUrl: 'https://app.getsortio.com/api'
  };
  
  const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;
  
  export default config;