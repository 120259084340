import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-scroll';

const TableOfContents = ({ tableOfContents }) => {
  const [activeSection, setActiveSection] = useState('');

  const getActiveSection = useCallback(() => {
    const sections = tableOfContents.map(item => document.getElementById(item.id));
    const scrollPosition = window.scrollY;

    for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i];
      if (section && section.offsetTop <= scrollPosition + 100) {
        return tableOfContents[i].id;
      }
    }

    return tableOfContents[0]?.id || '';
  }, [tableOfContents]);

  useEffect(() => {
    const handleScroll = () => {
      const currentSection = getActiveSection();
      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: '-20% 0% -80% 0%', threshold: 0.1 }
    );

    tableOfContents.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) observer.observe(element);
    });

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial active section

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [tableOfContents, activeSection, getActiveSection]);

  return (
    <nav className="sticky top-24 max-h-[calc(100vh-6rem)] overflow-auto">
      <h2 className="text-xl font-semibold mb-4 text-white">Table of Contents</h2>
      <ul className="space-y-2">
        {tableOfContents.map((item) => (
          <li key={item.id}>
            <Link
              to={item.id}
              spy={true}
              smooth={true}
              duration={500}
              offset={-100}
              className={`block py-1 px-2 rounded transition-colors cursor-pointer ${
                activeSection === item.id
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TableOfContents;