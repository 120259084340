import React from 'react';
import { Download, Check } from 'lucide-react';
import { Link } from 'react-router-dom';

const PricingSection = () => (
  <section id="pricing" className="py-20 bg-gray-800 text-white">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-4xl font-bold mb-8">Simple, One-Time Pricing</h2>
      <div className="max-w-2xl mx-auto">
        <div className="bg-gray-900 p-8 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4">Lifetime Access</h3>
          <p className="text-5xl font-bold mb-6">$5<span className="text-2xl font-normal"> one-time</span></p>
          <ul className="list-none mb-8 space-y-3">
            <li className="flex items-center justify-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              Unlimited sorting
            </li>
            <li className="flex items-center justify-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              All features included
            </li>
            <li className="flex items-center justify-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              Priority support
            </li>
            <li className="flex items-center justify-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              Free trial included
            </li>
            <li className="flex items-center justify-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              Lifetime updates
            </li>
          </ul>
          <Link to="/download" className="block bg-gray-800 p-4 rounded-lg hover:bg-gray-700 transition-colors">
            <p className="flex items-center justify-center text-lg">
              <Download className="w-5 h-5 mr-2" />
              Download the app to start your free trial
            </p>
            <p className="mt-2 text-sm text-gray-400">
              Pay once, use forever after your trial
            </p>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default PricingSection;