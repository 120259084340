import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const AboutUsPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white py-12">
      <Helmet>
        <title>About Us - Sortio</title>
        <meta name="description" content="Learn about Sortio, the AI-powered file organization app, and our mission to simplify digital organization." />
      </Helmet>
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-bold mb-8">About Sortio</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
          <p>At Sortio, we're on a mission to revolutionize the way people organize their digital lives. We believe that with the power of AI, we can make file management effortless and intuitive for everyone.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
          <p>Sortio was born out of a simple frustration: the endless hours spent organizing files and folders. Our founders, a team of AI enthusiasts and software engineers, came together with a vision to create a tool that would make file organization as easy as having a conversation.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What We Do</h2>
          <p>Sortio is an AI-powered desktop application that organizes your files based on natural language instructions. Simply tell Sortio how you want your files organized, and watch as it intelligently sorts and categorizes your digital content.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
          <ul className="list-disc pl-6">
            <li><strong>Simplicity:</strong> We believe technology should simplify your life, not complicate it.</li>
            <li><strong>Privacy:</strong> Your data is yours. We design our products with privacy at the forefront.</li>
            <li><strong>Innovation:</strong> We're constantly pushing the boundaries of what's possible with AI and file management.</li>
            <li><strong>User-Centric:</strong> Everything we do is with our users in mind. Your feedback drives our development.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Join Us on Our Journey</h2>
          <p>We're just getting started, and we're excited to have you with us on this journey. Whether you're a tech enthusiast, a busy professional, or someone who just wants a tidier digital life, Sortio is here to help.</p>
          <p className="mt-4">Ready to experience the future of file organization? <Link to="/download" className="text-blue-400 hover:underline">Download Sortio</Link> today and see the difference for yourself.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
          <p>We love hearing from our users! If you have any questions, suggestions, or just want to say hello, please don't hesitate to <Link to="/#contact" className="text-blue-400 hover:underline">contact us</Link>.</p>
        </section>
      </div>
    </div>
  );
};

export default AboutUsPage;