import React, { useState } from 'react';
import { Send, Lightbulb } from 'lucide-react';

const ContactSection = () => {
  const [formType, setFormType] = useState('contact');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);
  
    const endpoint = 'https://app.getsortio.com/api/contact-or-suggest/';
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          type: formType,
          name: name, 
          email: email, 
          message: message 
        }),
      });
  
      if (response.ok) {
        setSubmitStatus('success');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        const errorData = await response.json();
        console.error('Submission error:', errorData);
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setSubmitStatus('error');
    }
  
    setIsSubmitting(false);
  };

  return (
    <section id="contact" className="py-20 bg-gray-800 text-white">
      <div className="container mx-auto px-4 max-w-lg">
        <h2 className="text-4xl font-bold mb-8 text-center">Get in Touch</h2>
        <div className="mb-6 flex justify-center space-x-4">
          <button
            className={`px-4 py-2 rounded-full ${formType === 'contact' ? 'bg-blue-600' : 'bg-gray-700'}`}
            onClick={() => setFormType('contact')}
          >
            <Send className="inline-block mr-2 h-5 w-5" />
            Contact Us
          </button>
          <button
            className={`px-4 py-2 rounded-full ${formType === 'suggestion' ? 'bg-blue-600' : 'bg-gray-700'}`}
            onClick={() => setFormType('suggestion')}
          >
            <Lightbulb className="inline-block mr-2 h-5 w-5" />
            Suggest Feature
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:border-blue-500"
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:border-blue-500"
            required
          />
          <textarea
            placeholder={formType === 'contact' ? "Your Message" : "Describe Your Feature Suggestion"}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            className="w-full p-3 rounded bg-gray-700 border border-gray-600 focus:outline-none focus:border-blue-500"
            required
          ></textarea>
          <button 
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded transition duration-300 flex items-center justify-center"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : formType === 'contact' ? 'Send Message' : 'Submit Suggestion'}
          </button>
        </form>
        {submitStatus === 'success' && (
          <div className="mt-4 p-3 bg-green-600 text-white rounded">
            Thank you! We've received your {formType === 'contact' ? 'message' : 'suggestion'} and will get back to you shortly.
          </div>
        )}
        {submitStatus === 'error' && (
          <div className="mt-4 p-3 bg-red-600 text-white rounded">
            Oops! Something went wrong. Please try again later.
          </div>
        )}
      </div>
    </section>
  );
};

export default ContactSection;