import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white py-12">
      <Helmet>
        <title>Privacy Policy - Sortio</title>
        <meta name="description" content="Privacy Policy for Sortio, the AI-powered file organization app." />
      </Helmet>
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p>At Sortio, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our desktop application.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Information We Don't Collect</h2>
          <p>We want to be clear about what we don't do:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>We do not collect or store the contents of your files.</li>
            <li>We do not retain any personal information beyond what's necessary for the functioning of the app.</li>
            <li>We do not use your data to train our AI models.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Information We Do Collect</h2>
          <p>The only information we process is:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Filenames (not file contents) for the purpose of organizing your files.</li>
            <li>Basic usage data to improve our service (e.g., app performance, error logs).</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. How We Use Your Information</h2>
          <p>We use the collected information solely for:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Providing and maintaining our file organization service.</li>
            <li>Improving and optimizing our application.</li>
            <li>Responding to your inquiries and customer service requests.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
          <p>We implement robust security measures to protect your information:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>All data processed through our app is encrypted.</li>
            <li>We use industry-standard protocols to safeguard your information.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
          <p>You have the right to:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>Access the personal information we hold about you.</li>
            <li>Request that your personal information be corrected or deleted.</li>
            <li>Object to the processing of your personal information.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please <Link to="/#contact" className="text-blue-400 hover:underline">contact us</Link>.</p>
        </section>

        <p className="text-sm text-gray-400">Last Updated: October 12, 2024</p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;