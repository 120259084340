import React from 'react';

const ArticleStructuredData = ({ article, category }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": article.title,
    "datePublished": article.created_at,
    "dateModified": article.updated_at,
    "author": {
      "@type": "Organization",
      "name": "Sortio"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Sortio",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.getsortio.com/logo.png"
      }
    },
    "description": article.excerpt,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://www.getsortio.com/articles/${category}/${article.slug}`
    }
  };

  if (article.youtube_video_url) {
    structuredData.video = {
      "@type": "VideoObject",
      "name": article.title,
      "description": article.excerpt,
      "thumbnailUrl": `https://img.youtube.com/vi/${getYouTubeVideoId(article.youtube_video_url)}/0.jpg`,
      "uploadDate": article.created_at,
      "contentUrl": article.youtube_video_url,
      "embedUrl": `https://www.youtube.com/embed/${getYouTubeVideoId(article.youtube_video_url)}`
    };
  }

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  );
};

// Helper function to extract YouTube video ID from URL
const getYouTubeVideoId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

export default ArticleStructuredData;