import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const TermsAndConditionsPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white py-12">
      <Helmet>
        <title>Terms and Conditions - Sortio</title>
        <meta name="description" content="Terms and Conditions for using Sortio, the AI-powered file organization app." />
      </Helmet>
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-bold mb-8">Terms and Conditions</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p>Welcome to Sortio. These Terms and Conditions govern your use of our desktop application and services. By using Sortio, you agree to these terms.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Data Collection and Privacy</h2>
          <p>At Sortio, we prioritize your privacy and data security:</p>
          <ul className="list-disc pl-6 mt-2">
            <li>We do not store or retain any of your personal files or folders.</li>
            <li>Our app only processes filenames, not the contents of your files.</li>
            <li>We do not use any of your data for training our AI models.</li>
            <li>All data processed through our app is encrypted for your security.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Use of the Application</h2>
          <p>Sortio is designed to help organize your files using AI-powered algorithms. You are responsible for any changes made to your file system using our app.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property</h2>
          <p>Sortio and its original content, features, and functionality are owned by us and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Disclaimer</h2>
          <p>Sortio is provided "as is" without any warranties, expressed or implied. We do not warrant that the app will be error-free or uninterrupted.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
          <p>In no event shall Sortio be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the application.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Changes to Terms</h2>
          <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
          <p>If you have any questions about these Terms, please <Link to="/#contact" className="text-blue-400 hover:underline">contact us</Link>.</p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;